import React, { Component, useState } from "react";
import CartContext from "./CartContext";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export class AboutUs extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5 align-items-center">
              <div class="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="display-5 mb-4">
                  Welcome to bagfactry.com – The Bag Factory for the World
                </h1>
                <p class="mb-4 textAlign">
                  Trough our platform bagfactry.com, we don't just connect
                  businesses; we lead a revolution that brings the vibrant and
                  diverse capabilities of India's small-scale manufacturers
                  straight to your business, regardless of your location in the
                  world. We firmly believe that 'Made in India' is more than a
                  simple stamp of origin – it's a testament to superior
                  craftsmanship, meticulous attention to detail, and a unique
                  cultural heritage.
                </p>
                <p class="mb-4 textAlign">
                  bagfactry.com isn't just another procurement platform; it's an
                  elevated experience. Our commitment goes beyond geographical
                  boundaries and industry sectors, as we navigate complexities,
                  and cut through the noise to present you with the finest
                  products with absolute convenience. When you decide to partner
                  with bagfactry.com, you're not merely completing a
                  transaction; you're embarking on a transformative journey.
                  This journey blends trust and commitment with tradition and
                  innovation, culminating in the success of your business.
                </p>
                <p class="mb-4 textAlign">
                  We invite you to be a part of this revolution. Together, let's
                  redefine procurement, foster exponential growth, and build a
                  future where 'Made in India' resonates with unparalleled
                  quality and unwavering trust across the global marketplace.
                </p>

                <div class="d-flex align-items-center mb-4">
                  <div
                    class="flex-shrink-0 p-4 ms-4"
                    style={{
                      backgroundColor: "#B5E0F6",
                      color: "black",
                    }}
                  >
                    <h3
                      class="text-black text-center"
                      style={{
                        width: "150px",
                      }}
                    >
                      Our <br />
                      Vision{" "}
                    </h3>
                  </div>
                  <div class="ms-4">
                    <h4 class="">
                      "To be the world's trusted factory for diverse,
                      high-quality Indian products"
                    </h4>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-4">
                  <div
                    class="flex-shrink-0 p-4 ms-4"
                    style={{
                      backgroundColor: "#B5E0F6",
                      color: "black",
                    }}
                  >
                    <h3
                      class="text-center"
                      style={{
                        width: "150px",
                      }}
                    >
                      Our <br />
                      Mission{" "}
                    </h3>
                  </div>
                  <div class="ms-4">
                    <h4 class="">
                      "Simplifying global procurement by offering quality,
                      efficiency, and transparency in sourcing Indian products"
                    </h4>
                  </div>
                </div>
                <hr />
                <div class="row pt-2">
                  <div class="col-sm-6">
                    <div
                      class="d-flex align-items-center "
                      className="text-center"
                    >
                      <MdEmail size="48" color="red" />
                      <div class="ms-3">
                        <h3>
                          <p class="mb-2">Email us</p>
                          <p class="mb-0">
                            {this.context.storeSettings.storeEmailId}
                          </p>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div
                      class="d-flex align-items-center"
                      className="text-center"
                    >
                      <FaPhone size="48" color="red" />
                      <div class="ms-3">
                        <h3>
                          <p class="mb-2">Call us</p>
                          <p class="mb-0">
                            +{this.context.storeSettings.defaultCountryCode}{" "}
                            {this.context.storeSettings.storePhoneNo}
                          </p>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
