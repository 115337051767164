import React, { Component, useState } from "react";
import CartContext from "./CartContext";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { LoadSpinner } from "./LoadSpinner";
import Swal from "sweetalert2";
import { BlobServiceClient } from "@azure/storage-blob";
import { Link } from "react-router-dom";

import {
  Table,
  OverlayTrigger,
  Tooltip,
  Button,
  Row,
  Col,
  Form,
  FloatingLabel,
  Stack,
} from "react-bootstrap";

export class QuotePage extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      termsandconditions: false,
      loadSpinnerOpen: false,
      requestSubmited: false,
      quote: {
        customerName: "",
        contactNumber: "",
        emailId: "",
        // categoryName: "",
        // productName: "",
        // qty: 100,
        purposeOfProcurement: "",
        preferredLanguage: "",
        deliveryTimeline: "",
        // detailedRequirement: "",
      },
      productRequirements: [
        {
          categoryName: "",
          productName: "",
          costPerPiece: 0,
          qty: 100,
          seqNo: 1,
          quoteAmount: 0,
          detailedRequirement: "",
        },
      ],
    };
    this.inputFile = React.createRef();
  }

  handleFileUpload = async (e) => {
    this.ShowLoadSpinner();
    const { files } = e.target;

    for (let i = 0; i < files.length; i++) {
      await this.uploadFileToBlob(files[i]);
    }
    console.log("All uploaded ");
    this.HideLoadSpinner();
  };
  uploadFileToBlob = async (file) => {
    if (!file) return [];
    const blobService = new BlobServiceClient(
      this.context.store.storageBlobUrl + this.context.store.storageSasToken
    );
    const containerClient = blobService.getContainerClient(
      this.context.store.storageBlobContainerName
    );

    console.log("uploading:" + file.name);
    //upload file.
    await this.createBlobInContainer(containerClient, file);

    // get list of blobs in container
    //return getBlobsInContainer(containerClient);
  };

  createBlobInContainer = async (containerClient, file) => {
    // create blobClient for container
    var targetFileName =
      file.name.split(".")[0] +
      new Date().toDateString() +
      "." +
      file.name.split(".")[1];

    const blobClient = containerClient.getBlockBlobClient(
      "attachments/" + targetFileName
    );

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };

    // upload file
    await blobClient.uploadData(file, options);

    this.state.quote.attachmentFileName = targetFileName;
    this.setState({ quote: this.state.quote });

    console.log("uploaded:" + file.name);
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  GetSelectedMainCategoryId = (name) => {
    var r = this.context.mainCategories.filter((f) => f.name == name);

    if (r.length > 0) return r[0].id;

    return "";
  };

  handleAddProductRequirements = (e) => {
    this.setState({
      productRequirements: [
        ...this.state.productRequirements,
        {
          categoryName: "",
          productName: "",
          qty: 100,
          seqNo: this.state.productRequirements.length + 1,
          costPerPiece: 0,
          quoteAmount: 0,
          detailedRequirement: "",
        },
      ],
    });
  };

  handleProductRequirementsChange = (e, index) => {
    const { name, value } = e.target;
    // var name = "fileName";
    const list = [...this.state.productRequirements];
    list[index][name] = value;
    this.setState({ productRequirements: list });
  };

  GetProductsOfMainCategoryName = (categoryName) => {
    var results = this.context.prodsAll.filter(
      (f) => f.mainCategoryId == this.GetSelectedMainCategoryId(categoryName)
    );

    return results;
  };

  handleProductRequirementDelete = (i) => {
    const list = [...this.state.productRequirements];
    list.splice(i, 1);
    this.setState({ productRequirements: list });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "termsandconditions":
        this.setState({ termsandconditions: e.target.checked });
        return;
      case "customerName":
        this.state.quote.customerName = value;
        break;
      case "contactNumber":
        this.state.quote.contactNumber = value;
        break;
      case "emailId":
        this.state.quote.emailId = value;
        break;
      case "taxNo":
        this.state.quote.taxNo = value;
        break;
      case "attachmentFileName":
        this.state.quote.attachmentFileName = value;
        break;
      case "categoryName":
        this.state.quote.categoryName = value;
        break;
      case "productName":
        this.state.quote.productName = value;
        break;
      case "qty":
        if (value > 0) this.state.quote.qty = value;
        break;
      case "purposeOfProcurement":
        this.state.quote.purposeOfProcurement = value;
        break;
      case "preferredLanguage":
        this.state.quote.preferredLanguage = value;
        break;
      case "deliveryTimeline":
        this.state.quote.deliveryTimeline = value;
        break;
      case "detailedRequirement":
        this.state.quote.detailedRequirement = value;
        break;
    }
    this.setState({ quote: this.state.quote });
  };

  SubmitQuoteRequest = () => {
    this.ShowLoadSpinner();

    var str = {
      storeId: this.context.storeId,
      customerName: this.state.quote.customerName,
      contactNumber: this.state.quote.contactNumber,
      emailId: this.state.quote.emailId,
      // categoryName: this.state.quote.categoryName,
      // productName: this.state.quote.productName,
      // qty: this.state.quote.qty,
      purposeOfProcurement: this.state.quote.purposeOfProcurement,
      preferredLanguage: this.state.quote.preferredLanguage,
      deliveryTimeline: this.state.quote.deliveryTimeline,
      // detailedRequirement: this.state.quote.detailedRequirement,
      attachmentFileName: this.state.quote.attachmentFileName,
      taxNo: this.state.quote.taxNo,
      amountReceived: 0,
      taxPercentage: this.context.taxDetails.taxPercentage,
      quoteStatus: 1, //new request
      paymentStatus: 2, //1.Paid 2.Unpaid 3.PartiallyPaid
      quotationTotalAmount: 0,
      quoteItems: this.state.productRequirements,
    };

    axios
      .post(process.env.REACT_APP_API + "Consumers/SubmitQuoteRequest", str)
      .then((response) => {
        if (response.status === 200) {
          this.HideLoadSpinner();
          Swal.fire({
            title: "Information!",
            confirmButtonColor: "#23B14D",
            text: "Thank you. We have received your request. Our Team would get in touch with you shortly! ",
            confirmButtonText: "Ok",
          }).then((result) => {
            this.setState({ requestSubmited: true });
          });
        } else {
          this.HideLoadSpinner();
          Swal.fire({
            title: "Failed!",
            confirmButtonColor: "#23B14D",
            text: "Submit failed. Please try after sometime!",
            confirmButtonText: "Ok",
          }).then((result) => {});
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        Swal.fire({
          title: "Failed!",
          confirmButtonColor: "#23B14D",
          text: "Submit failed. Please try after sometime!",
          confirmButtonText: "Ok",
        }).then((result) => {});
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (
      this.state.productRequirements != undefined &&
      this.state.productRequirements.length > 0
    )
      this.SubmitQuoteRequest();
    else {
      event.stopPropagation();
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text: "Please add your requirement and try ",
        confirmButtonText: "Ok",
      }).then((result) => {
        event.stopPropagation();
      });
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.state.quote.customerName = this.context.userData.firstName
      ? this.context.userData.firstName
      : "" + " " + this.context.userData.lastName
      ? this.context.userData.lastName
      : "";
    this.state.quote.contactNumber = this.context.userData.mobileNo;
    this.state.quote.emailId = this.context.userData.emailId
      ? this.context.userData.emailId
      : "";
    this.setState({ quote: this.state.quote });
  };

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (this.state.requestSubmited == true) {
      return <Navigate to="/home" />;
    }
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <input
          style={{ display: "none" }}
          accept=".png,.jpg,.jpeg"
          ref={this.inputFile}
          onChange={this.handleFileUpload}
          type="file"
          multiple="multiple"
        />
        {window.innerWidth > 1200 ? (
          <>
            <img
              className="d-block image-blurred-edge"
              src={
                this.context.store.storageBlobUrl +
                this.context.store.storageBlobContainerName +
                "/images/" +
                "request-quote-banner.jpg" +
                this.context.store.storageSasToken
              }
              alt="First slide"
              style={{
                width: "100%",
                cursor: "pointer",
                // objectFit: "contain",
                height: "10cm",
              }}
            />
            <div
              className=" mx-0 my-3 border-0 CornersRounded"
              style={{
                marginLeft: this.context.theme.homeMarginSpace + "cm",
                marginRight: this.context.theme.homeMarginSpace + "cm",
                // backgroundColor: this.context.theme.homepageBackgroundColor,

                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              <h4 style={{ textAlign: "justify" }}>
                Welcome and start your procurement step here by requesting a
                quote. At BagFactry.com, we've simplified the sourcing process.
                By requesting a quote, our dedicated team would be able to
                provide the best quotation at competitive price. Trust
                Bagfactry.com to transform your procurement journey and provide
                a seamless, reliale solution. Your quote request is a ticket to
                a future of effortless and successful sourcing.
              </h4>
              <Form onSubmit={this.handleSubmit}>
                <div
                  className=" p-3 border CornersRoundedSmall"
                  style={{
                    // backgroundColor: "#c6ffff",
                    backgroundColor: "white",
                    paddingLeft: "200px",
                    paddingRight: "200px",
                  }}
                >
                  <h1 className="text-center fw-bold">Request Form</h1>
                  <Row>
                    <Col>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Your Name *"
                        className="mb-4"
                      >
                        <Form.Control
                          type="text"
                          size="lg"
                          placeholder=""
                          required
                          name="customerName"
                          value={this.state.quote.customerName}
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Your Contact Number *"
                        className="mb-4"
                      >
                        <Form.Control
                          type="text"
                          size="lg"
                          required
                          placeholder=""
                          disabled
                          name="contactNumber"
                          value={this.state.quote.contactNumber}
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Your Email *"
                        className="mb-4"
                      >
                        <Form.Control
                          type="email"
                          size="lg"
                          required
                          placeholder=""
                          name="emailId"
                          value={this.state.quote.emailId}
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Select Purpose of procurement *"
                        className="mb-4"
                      >
                        <Form.Select
                          type="text"
                          size="lg"
                          required
                          placeholder=""
                          name="purposeOfProcurement"
                          value={this.state.quote.purposeOfProcurement}
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="Personal">Personal</option>
                          <option value="Online Retailing">
                            Online Retailing
                          </option>
                          <option value="Offline Retailing">
                            Offline Retailing
                          </option>
                          <option value="Wholesale">Wholesale</option>
                          <option value="Event">Event</option>
                          <option value="Corporate">Corporate</option>
                          <option value="Education Institution">
                            Education Institution
                          </option>
                          <option value="NGO-Non Profit Organization">
                            NGO-Non Profit Organization
                          </option>
                          <option value="Government">Government</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Select Preferred Language *"
                        className="mb-4"
                      >
                        <Form.Select
                          type="text"
                          size="lg"
                          required
                          placeholder=""
                          name="preferredLanguage"
                          value={this.state.quote.preferredLanguage}
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="English">English</option>
                          <option value="Hindi">Hindi</option>
                          <option value="Kannada">Kannada</option>
                          <option value="Tamil">Tamil</option>
                          <option value="Telugu">Telugu</option>
                          <option value="Malayalam">Malayalam</option>
                        </Form.Select>
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Select Delivrey Timeline *"
                        className="mb-4"
                      >
                        <Form.Select
                          type="text"
                          size="lg"
                          required
                          placeholder=""
                          name="deliveryTimeline"
                          value={this.state.quote.deliveryTimeline}
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="5 days">5 days</option>
                          <option value="10 days">10 days</option>
                          <option value="20 days">20 days</option>
                          <option value="30 days">30 days</option>
                          <option value="45 days">45 days</option>
                          <option value="60 days">60 days</option>
                          <option value="90 days">90 days</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="GST No "
                        className="mb-4"
                      >
                        <Form.Control
                          type="text"
                          size="lg"
                          placeholder=""
                          name="taxNo"
                          value={this.state.quote.taxNo}
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </FloatingLabel>
                      Attachment File :&nbsp;
                      {this.state.quote.attachmentFileName != undefined &&
                      this.state.quote.attachmentFileName != "" ? (
                        <>
                          <div className="border CornersRoundedSmall p-1">
                            <b>{this.state.quote.attachmentFileName}</b>{" "}
                            &nbsp;&nbsp;&nbsp;
                            <a
                              href={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/attachments/" +
                                this.state.quote.attachmentFileName +
                                this.context.store.storageSasToken
                              }
                              target="_blank"
                              className="text-green text-decoration-none"
                            >
                              View
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                              className="bg-green"
                              onClick={(e) => {
                                Swal.fire({
                                  title: "Warning!",
                                  confirmButtonColor: "#23B14D",
                                  cancelButtonColor: "#dbdbdb",
                                  text: "Do you want to delete?",
                                  showCancelButton: "true",
                                  confirmButtonText: "Yes",
                                  cancelButtonText: "No",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    this.state.quote.attachmentFileName = "";
                                    this.setState({ quote: this.state.quote });
                                  }
                                });
                              }}
                            >
                              X
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <Link
                            className="text-green text-decoration-none"
                            onClick={(e) => {
                              // this.state.quote.attachmentFileName =
                              this.inputFile.current.click();
                              // this.setState({ quote: this.state.quote });
                            }}
                          >
                            click to attach{" "}
                          </Link>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row className="m-0">
                    <Col md={8} className="text-start text-dark my-3">
                      <span>
                        <h4>
                          <b>Enter your requirements below: *</b>
                        </h4>
                      </span>
                    </Col>
                    <Col md={4} className="text-end align-bottom">
                      {/* <Button
                    variant="primary"
                    classname="px-2"
                    // onClick={(e) => this.handleAddImages(e)}
                  >
                    + Add
                  </Button> */}
                      &nbsp;&nbsp;
                    </Col>
                  </Row>
                  <Row className="m-0 p-0 bg-light border-0">
                    <Col className="m-0 p-0">
                      <table striped className="text-start m-0 p-0">
                        <thead className="bg-light border-0">
                          <tr size="lg" className="border-0">
                            <th width="2%" className="text-center">
                              #
                            </th>
                            <th width="15%" className="text-center">
                              Category
                            </th>
                            <th width="15%" className="text-center">
                              Product Name
                            </th>
                            <th width="10%" className="text-center">
                              Image
                            </th>
                            <th width="10%" className="text-center">
                              Qty
                            </th>
                            <th width="30%" className="text-center">
                              Detailed Requirement
                            </th>
                            <th width="3%" className="text-center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.productRequirements.map((row, i) => (
                            <>
                              <tr className="border m-0 p-0 text-center">
                                <td>{i + 1}</td>
                                <td>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Select Category"
                                  >
                                    <Form.Select
                                      type="text"
                                      size="lg"
                                      required
                                      placeholder=""
                                      name="categoryName"
                                      value={
                                        this.state.productRequirements[i]
                                          .categoryName
                                      }
                                      onChange={(e) =>
                                        this.handleProductRequirementsChange(
                                          e,
                                          i
                                        )
                                      }
                                    >
                                      <option value=""></option>
                                      {this.context.mainCategories.map(
                                        (maincategory) => (
                                          <>
                                            <option value={maincategory.name}>
                                              {maincategory.name}
                                            </option>
                                          </>
                                        )
                                      )}
                                    </Form.Select>
                                  </FloatingLabel>
                                </td>
                                <td>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Select Product"
                                  >
                                    <Form.Select
                                      type="text"
                                      size="lg"
                                      placeholder=""
                                      required
                                      name="productName"
                                      value={
                                        this.state.productRequirements[i]
                                          .productName
                                      }
                                      onChange={(e) =>
                                        this.handleProductRequirementsChange(
                                          e,
                                          i
                                        )
                                      }
                                    >
                                      <option value=""></option>
                                      {this.GetProductsOfMainCategoryName(
                                        this.state.productRequirements[i]
                                          .categoryName
                                      ).map((prod) => (
                                        <>
                                          <option value={prod.name}>
                                            {prod.name}
                                          </option>
                                        </>
                                      ))}
                                    </Form.Select>
                                  </FloatingLabel>
                                </td>
                                <td></td>
                                <td>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Enter Quantity"
                                  >
                                    <Form.Control
                                      type="number"
                                      size="lg"
                                      required
                                      placeholder=""
                                      name="qty"
                                      value={
                                        this.state.productRequirements[i].qty
                                      }
                                      onChange={(e) =>
                                        this.handleProductRequirementsChange(
                                          e,
                                          i
                                        )
                                      }
                                    />
                                  </FloatingLabel>
                                </td>

                                <td>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Please share more details of your requirements"
                                  >
                                    <Form.Control
                                      as="textarea"
                                      size="lg"
                                      rows={5}
                                      maxLength={499}
                                      style={{ height: "120px" }}
                                      name="detailedRequirement"
                                      value={
                                        this.state.productRequirements[i]
                                          .detailedRequirement
                                      }
                                      onChange={(e) =>
                                        this.handleProductRequirementsChange(
                                          e,
                                          i
                                        )
                                      }
                                    />
                                  </FloatingLabel>
                                </td>
                                <td className="text-center ">
                                  <OverlayTrigger
                                    overlay={<Tooltip>Delete!</Tooltip>}
                                  >
                                    <Button
                                      variant="outline-danger"
                                      size="lg"
                                      onClick={(e) => {
                                        Swal.fire({
                                          title: "Warning!",
                                          confirmButtonColor: "#23B14D",
                                          cancelButtonColor: "#dbdbdb",
                                          text: "Are you sure you want to Delete?",
                                          showCancelButton: "true",
                                          cancelButtonText: "No",
                                          confirmButtonText: "Yes",
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            this.handleProductRequirementDelete(
                                              i
                                            );
                                          }
                                        });
                                      }}
                                      className=""
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        fill="currentColor"
                                        class="bi bi-trash"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path
                                          fill-rule="evenodd"
                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                        />
                                      </svg>
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-end py-2">
                      <Button
                        // variant="primary"
                        classname="px-2"
                        size="lg"
                        onClick={(e) => this.handleAddProductRequirements(e)}
                        style={{
                          backgroundColor: "transparent",
                          color: "black",
                        }}
                      >
                        + Add another requirement
                      </Button>
                    </Col>
                  </Row>
                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Stack direction="horizontal">
                      <Form.Check
                        name="termsandconditions"
                        required
                        checked={this.state.termsandconditions == true}
                        label=""
                        onChange={(e) => this.handleInputChange(e)}
                      />
                      I agree to the User Agreement of BagFactry.com
                    </Stack>
                  </Form.Group>
                  <div className="  text-center">
                    <Button
                      className=" border-0 buttonwithshadow mx-0 text-center px-3 CornersRoundedSmall"
                      type="submit"
                      style={{
                        backgroundColor: "#B5E0F6",
                        color: "black",
                        fontSize: "32px",
                        fontFamily: "Helvetica",
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </>
        ) : (
          <>
            <br />
            <br />
            <br />
            <img
              className="image-blurred-edge"
              src={
                this.context.store.storageBlobUrl +
                this.context.store.storageBlobContainerName +
                "/images/" +
                "request-quote-banner.jpg" +
                this.context.store.storageSasToken
              }
              alt="First slide"
              style={{
                width: "100%",
                cursor: "pointer",
                // objectFit: "contain",
                height: "3cm",
              }}
            />
            <div style={{ textAlign: "justify", fontFamily: "cursive" }}>
              Welcome and start your procurement step here by requesting a
              quote. At BagFactry.com, we've simplified the sourcing process. By
              requesting a quote, our dedicated team would be able to provide
              the best quotation at competitive price. Trust Bagfactry.com to
              transform your procurement journey and provide a seamless, reliale
              solution. Your quote request is a ticket to a future of effortless
              and successful sourcing.
            </div>
            <Form onSubmit={this.handleSubmit}>
              <div
                className=" p-3 border CornersRoundedSmall"
                style={{
                  // backgroundColor: "#c6ffff",
                  backgroundColor: "white",
                  paddingLeft: "200px",
                  paddingRight: "200px",
                }}
              >
                <h1 className="text-center fw-bold">Request Form</h1>
                <Row>
                  <Col>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Your Name"
                      className="mb-4"
                    >
                      <Form.Control
                        type="text"
                        size="lg"
                        placeholder=""
                        required
                        name="customerName"
                        value={this.state.quote.customerName}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Your Contact Number"
                      className="mb-4"
                    >
                      <Form.Control
                        type="text"
                        size="lg"
                        required
                        placeholder=""
                        disabled
                        name="contactNumber"
                        value={this.state.quote.contactNumber}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Your Email"
                      className="mb-4"
                    >
                      <Form.Control
                        type="email"
                        size="lg"
                        required
                        placeholder=""
                        name="emailId"
                        value={this.state.quote.emailId}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Select Purpose of procurement"
                      className="mb-4"
                    >
                      <Form.Select
                        type="text"
                        size="lg"
                        required
                        placeholder=""
                        name="purposeOfProcurement"
                        value={this.state.quote.purposeOfProcurement}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        <option value="Personal">Personal</option>
                        <option value="Online Retailing">
                          Online Retailing
                        </option>
                        <option value="Offline Retailing">
                          Offline Retailing
                        </option>
                        <option value="Wholesale">Wholesale</option>
                        <option value="Event">Event</option>
                        <option value="Corporate">Corporate</option>
                        <option value="Education Institution">
                          Education Institution
                        </option>
                        <option value="NGO-Non Profit Organization">
                          NGO-Non Profit Organization
                        </option>
                        <option value="Government">Government</option>
                      </Form.Select>
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Select Preferred Language"
                      className="mb-4"
                    >
                      <Form.Select
                        type="text"
                        size="lg"
                        required
                        placeholder=""
                        name="preferredLanguage"
                        value={this.state.quote.preferredLanguage}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        <option value="English">English</option>
                        <option value="Hindi">Hindi</option>
                        <option value="Kannada">Kannada</option>
                        <option value="Tamil">Tamil</option>
                        <option value="Telugu">Telugu</option>
                        <option value="Malayalam">Malayalam</option>
                      </Form.Select>
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Select Delivrey Timeline"
                      className="mb-4"
                    >
                      <Form.Select
                        type="text"
                        size="lg"
                        required
                        placeholder=""
                        name="deliveryTimeline"
                        value={this.state.quote.deliveryTimeline}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        <option value="5 days">5 days</option>
                        <option value="10 days">10 days</option>
                        <option value="20 days">20 days</option>
                        <option value="30 days">30 days</option>
                        <option value="45 days">45 days</option>
                        <option value="60 days">60 days</option>
                        <option value="90 days">90 days</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col className="text-start text-dark my-3">
                    <span>
                      <h4>
                        <b>Enter your requirements below:</b>
                      </h4>
                    </span>
                  </Col>
                </Row>
                <Row className="m-0 p-0 bg-light border-0">
                  <Col className="m-0 p-0">
                    <table striped className="text-start m-0 p-0 border w-100">
                      <tbody>
                        {this.state.productRequirements.map((row, i) => (
                          <>
                            <tr className="border m-0 p-0 text-start">
                              <td width="10%">{i + 1}</td>
                              <td width="80%">
                                Category:
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Select Category"
                                >
                                  <Form.Select
                                    type="text"
                                    size="lg"
                                    required
                                    placeholder=""
                                    name="categoryName"
                                    value={
                                      this.state.productRequirements[i]
                                        .categoryName
                                    }
                                    onChange={(e) =>
                                      this.handleProductRequirementsChange(e, i)
                                    }
                                  >
                                    <option value=""></option>
                                    {this.context.mainCategories.map(
                                      (maincategory) => (
                                        <>
                                          <option value={maincategory.name}>
                                            {maincategory.name}
                                          </option>
                                        </>
                                      )
                                    )}
                                  </Form.Select>
                                </FloatingLabel>
                                Product Name:
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Select Product"
                                >
                                  <Form.Select
                                    type="text"
                                    size="lg"
                                    placeholder=""
                                    required
                                    name="productName"
                                    value={
                                      this.state.productRequirements[i]
                                        .productName
                                    }
                                    onChange={(e) =>
                                      this.handleProductRequirementsChange(e, i)
                                    }
                                  >
                                    <option value=""></option>
                                    {this.GetProductsOfMainCategoryName(
                                      this.state.productRequirements[i]
                                        .categoryName
                                    ).map((prod) => (
                                      <>
                                        <option value={prod.name}>
                                          {prod.name}
                                        </option>
                                      </>
                                    ))}
                                  </Form.Select>
                                </FloatingLabel>
                                Quantity:
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Enter Quantity"
                                >
                                  <Form.Control
                                    type="number"
                                    size="lg"
                                    required
                                    placeholder=""
                                    name="qty"
                                    value={
                                      this.state.productRequirements[i].qty
                                    }
                                    onChange={(e) =>
                                      this.handleProductRequirementsChange(e, i)
                                    }
                                  />
                                </FloatingLabel>
                                Detailed Requirement(if any):
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Please share more details of your requirements"
                                >
                                  <Form.Control
                                    as="textarea"
                                    size="lg"
                                    rows={5}
                                    maxLength={499}
                                    style={{ height: "120px" }}
                                    name="detailedRequirement"
                                    value={
                                      this.state.productRequirements[i]
                                        .detailedRequirement
                                    }
                                    onChange={(e) =>
                                      this.handleProductRequirementsChange(e, i)
                                    }
                                  />
                                </FloatingLabel>
                              </td>
                              <td width="10%">
                                <OverlayTrigger
                                  overlay={<Tooltip>Delete!</Tooltip>}
                                >
                                  <Button
                                    variant=""
                                    size="lg"
                                    onClick={(e) => {
                                      Swal.fire({
                                        title: "Warning!",
                                        confirmButtonColor: "#23B14D",
                                        cancelButtonColor: "#dbdbdb",
                                        text: "Are you sure you want to Delete?",
                                        showCancelButton: "true",
                                        cancelButtonText: "No",
                                        confirmButtonText: "Yes",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          this.handleProductRequirementDelete(
                                            i
                                          );
                                        }
                                      });
                                    }}
                                    className=""
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="22"
                                      fill="currentColor"
                                      class="bi bi-trash"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                      <path
                                        fill-rule="evenodd"
                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                      />
                                    </svg>
                                  </Button>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-end py-2">
                    <Button
                      classname="px-2"
                      size="lg"
                      onClick={(e) => this.handleAddProductRequirements(e)}
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                      }}
                    >
                      + Add another requirement
                    </Button>
                  </Col>
                </Row>
                <Form.Group as={Row} className="mb-1" controlId="">
                  <Stack direction="horizontal">
                    <Form.Check
                      name="termsandconditions"
                      required
                      checked={this.state.termsandconditions == true}
                      label=""
                      onChange={(e) => this.handleInputChange(e)}
                    />
                    I agree to the User Agreement of BagFactry.com
                  </Stack>
                </Form.Group>
                <div className="  text-center">
                  <Button
                    className=" border-0 buttonwithshadow mx-0 text-center px-3 CornersRoundedSmall"
                    type="submit"
                    style={{
                      backgroundColor: "#B5E0F6",
                      color: "black",
                      fontSize: "32px",
                      fontFamily: "Helvetica",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </>
        )}
      </>
    );
  }
}
